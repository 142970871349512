import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getActive(to, from) {
    const { data } = await this._axios.get(`/nginxApi.php?get=problemsStatDetailed&chartType=statusTimeline&to=${from}&from=${to}`);

    return data;
  }

  async getTypes() {
    const { data } = await this._axios.get('/ajax.php?action=getAllProblemTypes&asArray=1');

    return data;
  }

  async geеSCUD(to, from) {
    /// env/type
    /// transport/rest/stat/bus
    const { data } = await this._axios.get(`/sigur/rest/turnstile/count/?to=${from}&from=${to}`);

    return data;
  }

  async getType(to, from) {
    /// env/type
    /// transport/rest/stat/bus
    const data = [];
    let dataToo = [];
    let dataOne = [];
    dataOne = await this._axios.get('/transport/rest/env/type');
    dataToo = await this._axios.post('/transport/rest/stat/special', {
      to: from,
      from: to
    });
    data.special = dataToo.data.stats;
    data.type = dataOne.data;

    return data;
  }

  async getBus(from, to) {
    /// env/type
    /// transport/rest/stat/bus
    let data = [];
    data = await this._axios.post('/transport/rest/stat/bus', {
      to,
      from
    });

    return data;
  }
  async getAllProblem(ids) {
    const { data } = await this._axios.post('/ajax.php?action=getAll', {
      taskIds: ids
    });
    return data;
  }

  async getMarket(from, to) {
    let data = [];
    data = await this._axios.post('/marketplace/sport/rest/stat', {
      to,
      from
    });

    return data;
  }

  async getFile(json) {
    const { data } = await this._axios.post('/ajax.php?action=downloadAndPrint&casse=csv', {
      dateFrom: json.dateFrom,
      dateTo: json.dateTo,
      extraOptions: json.extraOptions,
      filterStatuses: json.filterStatuses,
      filterTypes: json.filterTypes,
      reportType: json.reportType
    });

    return data;
  }

  async getControl(to, from, period = null) {
    let control = '';
    if (to || from) {
      control = `?to=${to}&from=${from}`;
    }
    if (period) {
      control = `?period=${period}`;
    }
    const data = [];
    const dataRest = await this._axios.get('/smartSensor/rest/resource');
    let count = 0;
    for (let i = 0; i < dataRest.data.length; i++) {
      if(dataRest?.data[i]?.id && (
        dataRest?.data[i]?.id == 1 ||
        dataRest?.data[i]?.id == 3 ||
        dataRest?.data[i]?.id == 5
      )) {
        const info = await this._axios.get(`/smartSensor/rest/measure/resource/${dataRest.data[i].id}/by/day${control}`);
        if (dataRest.data[i].active == true) {
          data[count] = {0: info.data.measures, 1: dataRest.data[i].title};
          count++;
        }
      }
    }

    return data;
  }

  async getSensor(id, period, from, to, interval = 'DAY') {
    if (from && to) {
      if(period == 'DAY') {
        interval = 'HOUR';
      }
      period = 'FROM';
    }
console.log(new Date(from), new Date(to))
    if (new Date(from).getTime() + (24 * 60 * 60 * 1000) == new Date(to).getTime()) {
      interval = 'HOUR';
    }
    let json = JSON.stringify({
      action: 'chart',
      sensorId: id,
      period: period,
      interval: interval,
      sourceName: 'weather',
      dateFrom: this.dateFilter(from),
      dateTo: this.dateFilter(to),
      meterChartCHeckbox: 0
    });

    const form = new FormData();
    form.append('json', json);
    const { data } = await this._axios.post('/ajax.php?action=loadWeatherChart', form);

    return data;
  }

  async getAllSensor() {
    const { data } = await this._axios.get('/ajax.php?action=loadWeather&v=3');

    return data;
  }

  async getAccess() {
    const { data } = await this._axios.get('/auth/rest/access');

    return data;
  }
  dateFilter(e) {
    const date = new Date(e);
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    let mm = date.getMonth() + 1;
    if (mm < 10) mm = `0${mm}`;
    const yy = date.getFullYear();

    return `${dd}.${mm}.${yy}`;
  }
}
