import axios from 'axios';
import rir from '../plugins/RirLib';

export default class ApiService {
  token = null;
  _axios = null;
  constructor() {
    // this.token = Vue.prototype.$keycloak.kk.token;
    this._axios = axios.create({
      // baseURL: process.env.VUE_APP_API_SERVER,
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.token ? `Bearer ${window.token}` : undefined
        // common: {
        //   Authorization: `Bearer ${Vue.prototype.$keycloak.kk.token}`
        // }
      }
    });
  }
}
