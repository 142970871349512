<template>
    <div
      class="w-full"
      :style="widthResize"
    >
      <div
        class="m-8px p-16px bg-white radius-16 border-arrival"
      >
        <div
          class="flex justify-between"
        >
          <div
            class="flex m-16px"
          >
            <div
              class="overflow-ellipsis webkit-box text-h20-24 color-titanic"
            >
              <r-tooltip
                title="Умные счетчики"
                position="top-center"
                :is-arrow="true"
                align-title="start"
                max-width="300px"
              >
                <template
                  #activator
                >
                  Умные счетчики
                </template>
              </r-tooltip>
            </div>
          </div>
          <div
            class="ml-16px flex"
          >
            <div
              class="relative"
            >
              <drop-down-button
                :items="periodList"
                :period="selectedPeriod"
                @select="onChangePeriod"
              />
            </div>
            <r-date-picker
              label=""
              class="cleaning_dateResurs hw-0"
              fill="rocky"
              v-model="period"
              @input="perodFunc"
              :period="true"
            />
            <div
              class="relative ml-4"
            >
              <additional-menu>
                <ul
                  class="obj-card__menu"
                >
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="resize"
                    @click="resizeOff"
                  >
                    <icon-graph
                      graph="resizeOff"
                    />
                    Свернуть
                  </li>
                </ul>
                <ul
                  class="obj-card__menu"
                >
                  <li
                    class="sulguni align-items-center flex pointer"
                    v-if="!resize"
                    @click="resizeOn"
                  >
                    <icon-graph
                      graph="resizeOn"
                    />
                    Развернуть
                  </li>
                </ul>
                <ul
                  class="obj-card__menu mt-4"
                  v-if="graph"
                  @click="graphOff"
                >
                  <li
                    class="sulguni align-items-center flex pointer"
                  >
                    <icon-graph
                      graph="graphOff"
                    />
                    Гистограмма
                  </li>
                </ul>
                <ul
                  class="obj-card__menu mt-4"
                  v-if="!graph"
                  @click="graphOn"
                >
                  <li
                    class="sulguni align-items-center flex pointer"
                  >
                    <icon-graph
                      graph="graphOn"
                    />
                    График
                  </li>
                </ul>
              </additional-menu>
            </div>
          </div>
        </div>
        <div class="rir-drag-upload-file__uploading" style="display: flex;height: 277px;" v-if="uploadGraph">
          <Loader style="height: 223px;"/>
        </div>
        <div
          v-if="!uploadGraph"
        >
          <div
            class="mt-24px flex scroll-x ml-4"
          >
            <a
              :class="'inline-flex pointer items-center' + (index > 1 ? ' ml-24px' : '')"
              v-for="(item, index) in dataInc"
              v-if="index > 0"
            >
              <r-checkbox
                :value="''"
                label=""
                @input="dataEndFunc(index)"
              />
              <div
                class="ml-8px radius-100 flex-none hw-8 mt-2px"
                :style="'background-color: '+color[index-1]"
              />
              <div
                class="opacity-72 ml-6px whitespace-nowrap text-c13 color-titanic mt-2px"
              >
                {{ resTitle[index] }}
              </div>
            </a>
          </div>
          <div
            v-if="notGraph"
          >
            <div
              class="mt-20px"
              :key="count"
            >
              <apexchart
                class="isSingleItem"
                :type="graph ? 'line' : 'bar'"
                :options="chartOptions"
                :series="series"
                height="223"
              />
            </div>
          </div>
          <div
            v-else
            class="overflow-hidden relative-mb"
          >
            <div
              width="100%"
              style="min-height: 238px;"
            >
              <div
                class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light hw ml-auto mr-auto"
              >
                <svg
                  width="476"
                  height="223"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  class="apexcharts-svg transparent"
                  transform="translate(0, 0)"
                >
                  <g
                    class="apexcharts-annotations"
                  />
                  <g
                    class="apexcharts-inner apexcharts-graphical"
                  >
                    <defs />
                  </g>
                  <text
                    font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                    x="238"
                    y="129.5"
                    text-anchor="middle"
                    dominant-baseline="auto"
                    font-size="16px"
                    fill="RGBA(4,21,62,0.49)"
                    class="apexcharts-text opacity-72"
                  >
                    {{ text }}
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import IconGraph from '@/components/IconGraph';
import DropDownButton from '@/components/DropDownButton';
import AdditionalMenu from '@/components/AdditionalMenu';
import { formatDate } from "@/helpers/utils";
import PlaceApi from '@/api/PlaceApi';
import Loader from "@/components/Loader.vue";

const api = new PlaceApi();

export default {
  name: 'ProblemResource',
  components: {
    Loader,
    IconGraph,
    apexchart,
    DropDownButton,
    AdditionalMenu
  },
  props: {},
  data() {
    return {
      uploadGraph: true,
      color: ['rgb(141, 201, 94)', 'rgb(187, 154, 244)', 'rgb(233, 163, 93)',
        '#FF4500', '#ADD8E6', '#FF00FF',
        '#8B008B', '#A52A2A', '#808080'
      ],
      resTitle: [],
      dataCheck: [],
      dataEndCheck: true,
      dataEndCoolCheck: true,
      days: [],
      dataRec: {},
      dataEndRec: {},
      dataInc: [],
      retTrue: true,
      data: [],
      dataEnd: [],
      widthResize: 'width: 100%',
      resize: true,
      graph: true,
      text: '',
      count: 0,
      boolDataCheck: true,
      countDays: 0,
      periodCheck: [],
      period: null,
      selectedPeriod: 'Месяц',
      notGraph: false,
      periodList: [
        { id: 'today', active: false, value: 'Сегодня' },
        { id: 'thisWeek', active: false, value: 'Неделя' },
        { id: 'thisMonth', active: true, value: 'Месяц' },
        { id: 'thisPeriod', active: false, value: 'Период' }
      ],
      selectedPeriodId: 'thisMonth',
      series: [],
      chartOptions: {}
    };
  },
  mounted() {
    let d = new Date();
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    d = formatDate(d);
    lastMonth = formatDate(new Date(lastMonth));
    this.timeApi(null, null, 'month', lastMonth, d);
  },
  methods: {
    notData() {
      this.notGraph = false;
      for (let i = 0; i < this.series.length; i++) {
        if (this.series[i].data.length) {
          this.notGraph = true;
        }
      }
      this.text = 'Нет данных за выбранный период';
    },
    dataFunc() {
      this.series = [];
      for (let i = 1; i < this.data.length; i++) {
        this.series.push({
          name: this.resTitle[i],
          data: this.dataCheck[i] ? this.data[i] : []
        });
      }
      this.notData();
      this.count++;
    },
    dataTimeFunc() {
      this.series = [];
      for (let i = 1; i < this.data.length; i++) {
        this.series.push({
          name: this.resTitle[i],
          data: this.dataCheck[i] ? this.data[i] : []
        });
      }
      this.uploadGraph = false;
      this.notData();
      this.chartOptions = {
        legend: {
          show: false
        },
        chart: {
          width: '400px',
          toolbar: {
            show: false
          },
          fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
          zoom: {
            enabled: false
          }
        },
        // fill: {
        //   colors: [ ({ value, index, w }) => {
        //     return (typeof upLvl === 'number' && typeof downLvl === 'number')
        //       ? (value < upLvl && value > downLvl ? '#81ABEE' : '#F196A5')
        //       : ('#81ABEE')
        //   } ],
        // },
        grid: {
          show: true,
          borderColor: '#E4EDFB',
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        colors: this.color,
        plotOptions: {
          bar: {
            columnWidth: '40%',
            borderRadius: 1
          }
        },
        dataLabels: {
          enabled: false
        },
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        xaxis: {
          showForNullSeries: true,
          categories: this.days[1],
          type: 'category',
          tickPlacement: 'between',
          labels: {
            offsetX: 3,
            rotate: -40,
            rotateAlways: true,
            showDuplicates: false,
            hideOverlappingLabels: false,
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          crosshairs: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          show: true,
          showForNullSeries: true,
          labels: {
            show: true,
            formatter(val) {
              return val;
            },
            style: {
              fontSize: '9px',
              fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
              fontWeight: 400
            }
          }
        },
        noData: {
          text: 'Нет данных за выбранный период',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: 'RGBA(4,21,62,0.49)',
            fontSize: '16px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif'
          }
        },
        markers: {
          size: 4,
          colors: '#fff',
          strokeWidth: 2,
          strokeColors: this.color,
          hover: {
            size: 4
          }
        },
        stroke: {
          show: true,
          width: 2
        }
      };
    },
    getAll(begin, end, rec = 1) {
      if (!begin || !end) {
        return false;
      }
      const ab = begin.split('-');
      const ae = end.split('-');
      const db = new Date();
      db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
      const de = new Date();
      de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
      const unixDb = db.getTime();
      const unixDe = de.getTime();
      let arr = [];
      let count = 0;
      for (let k = unixDb; k <= unixDe;) {
        if (this.dataRec[rec].length > 0 && this.dataRec[rec][count]) {
          let d = this.dataRec[rec][count].time * 1000;
          d = formatDate(new Date(d));

          let dateBool = false;
          for (let i = 0; i < this.dataRec[rec].length; i++) {
            if (formatDate(new Date(this.dataRec[rec][i].time * 1000)) === formatDate(new Date(k))) {
              dateBool = true;
              break;
            }
          }

          if (this.dataRec[rec] && dateBool) {
            this.data[rec].push(this.dataRec[rec][count].value.toFixed(1));
          } else if (this.dataRec[rec]) {
            this.data[rec].push(0);
          }
        } else if (this.dataRec[rec]) {
          this.data[rec].push(0);
        }
        if (this.retTrue) {
          this.dataInc = this.data;
          this.retTrue = false;
        }
        count++;
        arr.push(`${new Date(k).getDate()}.${(parseInt(new Date(k).getMonth()) + 1) <= 9 ? 0 : ''}${parseInt(new Date(k).getMonth()) + 1}`);
        k += 24 * 60 * 60 * 1000;
      }
      if (this.data[rec] === 0) {
        this.data[rec] = [];
      }
      arr = Array.from(new Set(arr));
      return arr;
    },
    dataEndFunc(type) {
      this.dataCheck[type] = !this.dataCheck[type];
      this.dataFunc();
      this.count++;
    },
    resizeOff() {
      this.resize = false;
      this.widthResize = 'width: 50%';
      this.count++;
    },
    resizeOn() {
      this.resize = true;
      this.widthResize = 'width: 100%';
      this.count++;
    },
    graphOff() {
      this.graph = false;
      this.count++;
    },
    graphOn() {
      this.graph = true;
      this.count++;
    },
    perodFunc(e) {
      if (this.selectedPeriodId === 'thisPeriod') {
        this.selectedPeriod = `${this.period[0].replace(/[-]/g, '.')}-${this.period[1] !== undefined ? this.period[1].replace(/[-]/g, '.') : '...'}`;
        this.dataEnd = [];
        this.data = [];
        this.timeApi(this.period[1], this.period[0], null, this.period[0], this.period[1]);
        this.periodCheck[0] = this.period[0];
        this.periodCheck[1] = this.period[1];
      }
    },
    timeApi(start, end, time, last, d) {
      api.getControl(start, end, time, last, d).then(response => {
        for (var i = 0; i <= response.length; i++) {
          this.data[i] = [];
        }
        for (var i = 1; i <= response.length; i++) {
          this.dataRec[i] = response[i - 1][0];
          this.days[i] = this.getAll(last, d, i);
          if (this.boolDataCheck) {
            this.dataCheck[i] = true;
          }
          this.resTitle[i] = response[i - 1][1];
        }
        this.boolDataCheck = false;
      }).finally(() => {
        this.dataTimeFunc();
        this.count++;
      });
    },
    onChangePeriod(e) {
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      this.dataEnd = [];
      this.data = [];
      let d = new Date();
      if (e.id === 'today') {
        d = formatDate(d);
        this.timeApi(null, null, 'day', d, d);
      }
      if (e.id === 'thisWeek') {
        let lastWeek = new Date();
        lastWeek = new Date().getTime() - 604800000;
        d = formatDate(d);
        lastWeek = formatDate(new Date(lastWeek));
        this.timeApi(null, null, 'week', lastWeek, d);
      }
      if (e.id === 'thisMonth') {
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        d = formatDate(d);
        lastMonth = formatDate(new Date(lastMonth));
        this.timeApi(null, null, 'month', lastMonth, d);
      }
      if (e.id === 'thisPeriod') {
        const parent = document.querySelectorAll('.cleaning_dateResurs')[0];
        parent.querySelectorAll('.RDatePicker__input')[0].click();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_dateResurs {
  visibility: hidden;

  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.m-20px {
  margin-bottom: 20px;
}

.hw-0 {
  height: 0;
  width: 0;
}

.relative-mb {
  height: 223px;
  position: relative;
  margin-bottom: 52px;
}

.hw-8 {
  width: 8px;
  height: 8px;
}

.h-277 {
  height: 277px;
}

.h-223 {
  height: 223px;
}

.transparent {
  background: transparent;
  margin-top: 26px;
}

.hw {
  width: 476px;
  height: 223px;
}
</style>
