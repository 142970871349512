<template>
  <div>
    <r-icon
      class="mr-3 inline-block mr-12px mt-1"
      v-if="graph === 'resizeOff'"
      icon="decrease"
      size="16"
      fill="rocky"
    />
    <r-icon
      class="mr-3 inline-block mr-12px mt-1"
      v-if="graph === 'resizeOn'"
      icon="increase"
      size="16"
      fill="rocky"
    />
    <r-icon
      class="mr-3 inline-block mr-12px mt-1"
      v-if="graph === 'graphOff'"
      icon="chart"
      size="16"
      fill="rocky"
    />
    <r-icon
      class="mr-3 inline-block mr-12px mt-1"
      v-if="graph === 'graphOn'"
      icon="graph"
      size="16"
      fill="rocky"
    />
  </div>
</template>

<script>
export default {
  name: 'IconGraph',
  props: {
    graph: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
</style>
